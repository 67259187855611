<template>
  <div v-if="has_data">
    <vca-row>
      <vca-card>
        <vca-row>
          <h3 class="headline-first">
            {{ $t("deposit.info.headline.deposit_sum") }}
          </h3>
          <h3 class="headline-end">{{ formatMoney(value.money) }}</h3>
        </vca-row>
        <vca-row>
          <h3 class="headline-first" :class="showDiv">
            {{ $t("deposit.info.headline.taking_sum") }}
          </h3>
          <h3 class="headline-end" :class="showDiv">
            {{ formatMoney(getFullAmount()) }}
          </h3>
        </vca-row>
      </vca-card>

      <vca-card>
        <h3 class="headline-first">
          {{ $t("deposit.list.reason_for_payment") }}:
          <span class="selectable">{{ value.reason_for_payment }}</span>
        </h3>
      </vca-card>
    </vca-row>
    <hr class="finance-dashed-line" />
    <vca-row>
      <vca-card class="deposit-styles">
        <h3 style="text-decoration: underline">
          {{ $t("deposit.info.deposit_units.headline") }}
        </h3>
        <br /><br />
        <div v-for="(_, index) in value.deposit_units" :key="index">
          <DepositUnitInfo v-model="value.deposit_units[index]" />
        </div>
      </vca-card>
      <vca-card class="deposit-styles">
        <h3 style="text-decoration: underline">
          {{ $t("deposit.info.taking.headline") }}
        </h3>
        <p>{{ $t("deposit.info.taking.description") }}</p>
        <br /><br />
        <DepositTakingInfo v-model="value" />
      </vca-card>
    </vca-row>
  </div>
  <div v-else class="deposit-styles">
    <p>{{ $t("deposit.info.taking.no_takings") }}</p>
  </div>
</template>
<script>
import DepositTakingInfo from "./info/DepositTakingInfo.vue";
import DepositUnitInfo from "./info/DepositUnitInfo.vue";
export default {
  name: "DepositFinanceInfo",
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          deposit_units: [],
        };
      },
    },
  },
  methods: {
    getFullAmount() {
      let amount = 0;
      this.value.deposit_units.forEach((e) => {
        if (e.id !== "") {
          e.taking.sources.forEach((f) => {
            amount += f.money.amount;
          });
        }
      });
      return { amount: amount, currency: "EUR" };
    },
  },
  computed: {
    showDiv() {
      if (this.getFullAmount().amount !== this.value.money.amount) {
        return "headline-error";
      }
      return "";
    },
    has_data() {
      if (this.value.deposit_units.length === 0) {
        return false;
      }

      let has_data = false;
      this.value.deposit_units.forEach((e) => {
        if (e.id !== "") {
          has_data = true;
        }
      });
      return has_data;
    },
  },

  components: { DepositUnitInfo, DepositTakingInfo },
};
</script>
<style lang="scss">
.headline-error {
  background-color: #ef5350;
  color: black;
}
.headline-first {
  flex-basis: 90%;
  font-size: larger;
}
.headline-end {
  flex-basis: 10%;
  text-align: end;
  font-size: larger;
}
hr.finance-dashed-line {
  width: 100%;
  border-top: 1px dashed black;
  border-bottom: 0;
}
.deposit-styles p,
.deposit-styles span,
h3 {
  padding: 0 10px;
}
.deposit-styles hr {
  width: 100%;
  border-top: 1px solid black;
  border-bottom: 0;
}
.selectable {
  user-select: all;
}
</style>
