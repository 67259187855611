<template>
  <div class="deposit">
    <p style="font-weight: bold">
      <a :href="takingLink(value.taking.id)" target="taking">
        {{
          $t("deposit.info.deposit_units.taking", [
            value.taking.name,
            event_type(value),
          ])
        }}
      </a>
    </p>
    <vca-row>
      <p>{{ $t("deposit.info.deposit_units.donation") }}</p>
      <p>{{ formatMoney(getDonationValue()) }}</p>
    </vca-row>
    <vca-row>
      <p>{{ $t("deposit.info.deposit_units.economic") }}</p>
      <p>{{ formatMoney(getEconomicValue()) }}</p>
    </vca-row>
    <hr />
    <vca-row :class="colError">
      <p>{{ $t("deposit.info.deposit_units.sum") }}</p>
      <p>{{ formatMoney(getAllValue()) }}</p>
    </vca-row>
    <vca-row :class="colError">
      <p>{{ $t("deposit.info.deposit_units.taking_sum") }}</p>
      <p>{{ formatMoney(value.money) }}</p>
    </vca-row>
    <br /><br />
  </div>
</template>
<script>
export default {
  name: "DepositUnitInfo",
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          taking: {},
        };
      },
    },
  },
  methods: {
    getDonationValue() {
      let amount = 0;
      this.value.taking.sources.forEach((e) => {
        if (e.norms === "donation") {
          amount += e.money.amount;
        }
      });
      return { amount: amount, currency: "EUR" };
    },
    getEconomicValue() {
      let amount = 0;
      this.value.taking.sources.forEach((e) => {
        if (e.norms === "economic") {
          amount += e.money.amount;
        }
      });
      return { amount: amount, currency: "EUR" };
    },
    getAllValue() {
      let amount = 0;
      this.value.taking.sources.forEach((e) => {
        amount += e.money.amount;
      });
      return { amount: amount, currency: "EUR" };
    },
    event_type(e) {
      if (e.taking.event.id === "") {
        return this.$t("deposit.info.taking.no_event.type");
      } else {
        return this.$t(
          "deposit.info.taking." + e.taking.event.type_of_event + ".type"
        );
      }
    },
  },
  computed: {
    colError() {
      if (this.value.money.amount !== this.getAllValue().amount) {
        return "col-error";
      }
      return "";
    },
  },
};
</script>
<style lang="scss">
.col-error {
  background-color: #ef5350;
  color: black;
}
</style>
