<template>
  <vca-row>
    <div>
      <vca-row>
        <vca-column>
          <vca-field :label="$t('taking.filter.search.header')">
            <h3>{{ $t("taking.filter.search.label.name") }}</h3>
            <vca-input
              v-model="filter.deposit_unit_name"
              :label="$t('taking.filter.search.label.name')"
              :placeholder="$t('taking.filter.search.placeholder.name')"
            >
            </vca-input>
            <h3>{{ $t("filter.deposits.reason_for_payment.label") }}</h3>
            <vca-input
              v-model="filter.reason_for_payment"
              :label="$t('filter.deposits.reason_for_payment.label')"
              :placeholder="
                $t('filter.deposits.reason_for_payment.placeholder')
              "
            >
            </vca-input>
          </vca-field>
          <vca-field
            :label="$t('taking.filter.crew.header')"
            v-if="hasSystemPermission('employee')"
          >
            <CrewFilterSelection v-model="filter.crew_id" @input="list()" />
          </vca-field>
        </vca-column>
        <vca-column>
          <vca-field :label="$t('filter.deposits.status.title')">
            <DepositStatusFilter v-model="filter.deposit_status" />
          </vca-field>
          <vca-field :label="$t('filter.deposits.external.title')">
            <DepositExternalFilter v-model="filter.deposit_has_external" />
          </vca-field>
        </vca-column>
      </vca-row>
      <div class="vca-center">
        <vca-cancel-button
          @click="reset"
          :placeholder="$t('table.filter.reset')"
        />
      </div>
    </div>
  </vca-row>
</template>
<script>
import CrewFilterSelection from "../../../filter/CrewFilterSelection.vue";
import DepositExternalFilter from "../../../filter/DepositExternalFilter.vue";
import DepositStatusFilter from "../../../filter/DepositStatusFilter.vue";
import { mapGetters } from "vuex";
export default {
  name: "DepositFilter",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      select: [],
    };
  },
  computed: {
    filter: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
  methods: {
    reset() {
      this.filter_all_roles = false;
      this.$emit("reset");
    },
  },
  components: {
    CrewFilterSelection,
    DepositStatusFilter,
    DepositExternalFilter,
  },
};
</script>
